import { useMemo, useState, useEffect } from 'react';

import Spinner from './Spinner';
import DatePicker from '../reusable/DatePicker';
import H1 from '../reusable/H1';
import { Button1, IconButton, ExportCSV } from '../reusable/Buttons';
import { ApiError } from '../reusable/Error';
import { Info } from './info';
import useTable from '../../hooks/useTable';
import { ToggleFilterBoxButton, TABLE } from '../reusable/Table';
import { FilterBox } from '../reusable/TableFilters.js';



export function SimpleView({page, data, columns, title, fileName, setError, setGet, showColumnTotals}) {

  const [info, setInfo] = useState(false);

  let csvHeaders = [];
  for (let c = 0; c < columns.length; c ++) {
    csvHeaders.push({
      label: columns[c]?.Header,
      key: columns[c]?.accessor,
    })
  }

  const rawData = useMemo(() => data?.data ?? [], [data.data]);
  const myTable = useTable(rawData, columns);
  const [showFilters, setShowFilters] = useState(true);

  return (
    <div className="h-full w-full">

      <div className="h-[10%] w-full">
        <div className="h-fit w-full flex justify-left">
          <H1 text={title} />
          <div className="ml-[1em]">
            <button className="h-[1.5em] w-[1.5em] bg-info bg-contain"
              onClick={() => setInfo(true)} >
            </button>
          </div>
        </div>
      </div>


      <div className="h-[90%] w-full relative">

        {info ? (<Info setInfo={() => setInfo(false)} page={page} />) : (<></>)}

        <div className="h-[7%] w-full flex justify-end">
          <IconButton onClick={() => setGet(true)} icon="refresh" />
          <ExportCSV data={myTable.data} headers={csvHeaders} fileName={fileName} />
        </div>

        <div className="h-[93%] w-full overflow-y-auto">
          {
            data?.error > 0 ?
            (<ApiError errorCode={data?.error}
              okFunc={() => setError(0)} />) :
            !data?.data ?
            (<Spinner />) :
            (
              <div className="h-full w-full">
                <div className="h-[5%] w-full">
                  <ToggleFilterBoxButton showFilters={showFilters} setShowFilters={setShowFilters} />
                </div>

                <div className={showFilters ? "h-[30%] w-full overflow-y-auto bg-bgTblRow2" : "h-[0%] w-fit overflow-y-clip"}>
                  <FilterBox tableInstance={myTable} showFilters={showFilters} />
                </div>

                <div className={showFilters ? "h-[65%] w-full overflow-y-auto" : "h-[95%] w-full overflow-y-auto"}>
                  <TABLE tableInstance={myTable} showColumnTotals={showColumnTotals} />
                </div>
              </div>
            )
          }
        </div>

      </div>

    </div>
  );
}








export function SetDateRange({start, end, setGet, setDateRange, setMode}) {
  const [s, setS] = useState('');
  const [e, setE] = useState('');
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if (submit && (s === '' || e === '')) {
      alert('Please select a date range');
      setSubmit(false);
      return;
    }

    if (submit && !(s === '' || e === '') && !(start === s && end === e)) {
      setDateRange({ start: s, end: e });
      return;
    }

    if (submit && start === s && end === e) {
      setGet(true);
      setMode('view-data');
      return;
    }

  }, [submit, setSubmit, s, e, setMode, start, end, setDateRange, setGet]);

  return (
    <div className="h-full w-full">

      <div className="h-[10%] flex justify-end">
        <button className="h-[1.5em] w-[1.5em] bg-close bg-contain" onClick={() => setMode('view-data')}></button>
      </div>

      <div className="h-[90%]">
        <h2 className="text-2xl">Select Date Range</h2>
        <DatePicker label="Start date" value={s} onChange={setS} />
        <DatePicker label="End date" value={e} onChange={setE} />
        <div className="">
          <div className="h-[1em]"></div>
          <Button1 onClick={() => {setSubmit(true)}} text="Submit" />
        </div>
      </div>

    </div>
  );
}





export const months = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
}

export function DateRangeView({page, data, setGet, setError, setDateRange, columns, mode, setMode, title, fileName}) {

  const [info, setInfo] = useState(false);

  function formatDateStr(dateStr) {
    const [yyyy, mm, dd] = dateStr.split('-');
    const formatted = `${dd} ${months[mm]} ${yyyy}`;
    return formatted;
  }

  let csvHeaders = [];
  for (let c = 0; c < columns.length; c++) {
    csvHeaders.push({
      label: columns[c]?.Header,
      key: columns[c]?.accessor,
    })
  }

  const rawData = useMemo(() => data?.data ?? [], [data.data]);
  const myTable = useTable(rawData, columns);
  const [showFilters, setShowFilters] = useState(true);

  return (
    <div className="h-full w-full">

      <div className="h-[10%] w-full">
        <div className="h-fit w-full flex justify-left">
          <H1 text={title} />
          <div className="ml-[1em]">
            <button className="h-[1.5em] w-[1.5em] bg-info bg-contain"
              onClick={() => setInfo(true)} >
            </button>
          </div>
        </div>
      </div>

      <div className="h-[90%] w-full relative">

        {info ? (<Info setInfo={() => setInfo(false)} page={page} />) : (<></>)}

        {mode === 'request-form' ? (
          <div className="absolute top-0 left-0 h-full w-full z-40 flex justify-center bg-bgSpinner/50 backdrop-blur-sm">
            <div className="w-[50%] h-full flex flex-col justify-center">
              <div className="w-full h-auto p-[1em] rounded-xl bg-bgMain drop-shadow-2xl">
                <SetDateRange
                  start={data.start}
                  end={data.end}
                  setGet={setGet}
                  setDateRange={setDateRange}
                  setMode={setMode}
                />
              </div>
            </div>
          </div>
        ) : ( <></> )}

        <div className="h-[7%] w-full flex justify-end">

          <div className="w-[50%] flex flex-row justify-left">
            <div className="w-fit h-full px-[.5em] flex flex-col justify-center text-lg font-bold">
              <div>{`${formatDateStr(data.start)}`}</div>
            </div>
            <div className="w-fit h-full px-[.5em] flex flex-col justify-center text-lg font-bold">-</div>
            <div className="w-fit h-full px-[.5em] flex flex-col justify-center text-lg font-bold">
              {`${formatDateStr(data.end)}`}
            </div>
            <div className="w-fit h-full px-[.5em] flex flex-col justify-center">
              <IconButton onClick={() => setMode('request-form')} icon="calendar" />
            </div>
          </div>

          <div className="w-[50%] flex justify-end">
            <IconButton onClick={() => setGet(true)} icon="refresh" />
            <ExportCSV data={myTable.data} headers={csvHeaders} fileName={fileName} />
          </div>

        </div>

        <div className="h-[93%] w-full overflow-y-auto">
          {
            data.error > 0 ?
            (<ApiError errorCode={data.error}
              okFunc={() => setError(0)} />) :
            !data.data ?
            (<Spinner />) :
            (
              <div className="h-full w-full">
                <div className="h-[5%] w-full">
                  <ToggleFilterBoxButton showFilters={showFilters} setShowFilters={setShowFilters} />
                </div>

                <div className={showFilters ? "h-[30%] w-full overflow-y-auto bg-bgTblRow2" : "h-[0%] w-fit overflow-y-clip"}>
                  <FilterBox tableInstance={myTable} showFilters={showFilters} />
                </div>

                <div className={showFilters ? "h-[65%] w-full overflow-y-auto" : "h-[95%] w-full overflow-y-auto"}>
                  <TABLE tableInstance={myTable} />
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}










export function SetDate({setGet, setDate, setMode}) {
  const [d, setD] = useState('');
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if (submit && d === '') {
      alert('Please select a date');
      setSubmit(false);
      return;
    }

    if (submit && d !== '') {
      setDate(d);
      setGet(true);
      setMode('view-data');
      setSubmit(false);
    }
  }, [submit, d, setMode, setDate, setGet]);

  return (
    <div className="h-full w-full">

      <div className="h-[10%] flex justify-end">
        <button className="h-[1.5em] w-[1.5em] bg-close bg-contain" onClick={() => setMode('view-data')}></button>
      </div>

      <div className="h-[90%]">
        <h2 className="text-2xl">Select Date</h2>
        <DatePicker label="Date" value={d} onChange={setD} />
        <div className="">
          <div className="h-[1em]"></div>
          <Button1 onClick={() => {setSubmit(true)}} text="Submit" />
        </div>
      </div>

    </div>
  );
}




export function SingleDateView({page, data, setGet, setError, setDate, columns, title, fileName}) {
  const [info, setInfo] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [mode, setMode] = useState('view-data');

  function formatDateStr(dateStr) {
    try {
      const [yyyy, mm, dd] = dateStr.split('-');
      const formatted = `${dd} ${months[mm]} ${yyyy}`;
      return formatted;
    } catch (e) {
      return '';
    }
  }

  let csvHeaders = [];
  for (let c = 0; c < columns.length; c++) {
    csvHeaders.push({
      label: columns[c]?.Header,
      key: columns[c]?.accessor,
    })
  }

  const rawData = useMemo(() => data?.data ?? [], [data.data]);
  const myTable = useTable(rawData, columns);

  return (
    <div className="h-full w-full">
      <div className="h-[10%] w-full">
        <div className="h-fit w-full flex justify-left">
          <H1 text={title} />
          <div className="ml-[1em]">
            <button className="h-[1.5em] w-[1.5em] bg-info bg-contain"
              onClick={() => setInfo(true)} >
            </button>
          </div>
        </div>
      </div>

      <div className="h-[90%] w-full relative">
        {info ? (<Info setInfo={() => setInfo(false)} page={page} />) : (<></>)}

        {mode === 'request-form' ? (
          <div className="absolute top-0 left-0 h-full w-full z-40 flex justify-center bg-bgSpinner/50 backdrop-blur-sm">
            <div className="w-[50%] h-full flex flex-col justify-center">
              <div className="w-full h-auto p-[1em] rounded-xl bg-bgMain drop-shadow-2xl">
                <SetDate
                  setGet={setGet}
                  setDate={setDate}
                  setMode={setMode}
                />
              </div>
            </div>
          </div>
        ) : ( <></> )}

        <div className="h-[7%] w-full flex justify-end">
          <div className="w-[50%] flex flex-row justify-left">
            <div className="w-fit h-full px-[.5em] flex flex-col justify-center text-lg font-bold">
              <div>{`${formatDateStr(data.date)}`}</div>
            </div>
            <div className="w-fit h-full px-[.5em] flex flex-col justify-center">
              <IconButton onClick={() => setMode('request-form')} icon="calendar" />
            </div>
          </div>

          <div className="w-[50%] flex justify-end">
            <IconButton onClick={() => setGet(true)} icon="refresh" />
            <ExportCSV data={myTable.data} headers={csvHeaders} fileName={fileName} />
          </div>
        </div>

        <div className="h-[93%] w-full overflow-y-auto">
          {
            data.error > 0 ?
            (<ApiError errorCode={data.error}
              okFunc={() => setError(0)} />) :
            !data.data ?
            (<Spinner />) :
            (
              <div className="h-full w-full">
                <div className="h-[5%] w-full">
                  <ToggleFilterBoxButton showFilters={showFilters} setShowFilters={setShowFilters} />
                </div>

                <div className={showFilters ? "h-[30%] w-full overflow-y-auto bg-bgTblRow2" : "h-[0%] w-fit overflow-y-clip"}>
                  <FilterBox tableInstance={myTable} showFilters={showFilters} />
                </div>

                <div className={showFilters ? "h-[65%] w-full overflow-y-auto" : "h-[95%] w-full overflow-y-auto"}>
                  <TABLE tableInstance={myTable} />
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
