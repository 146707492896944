import { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAccessToken } from '../../hooks';
import Api from '../../Api';
import { DateRangeView } from '../reusable/DataViews';
import {
  invHdrsData,
  invHdrsError,
  invHdrsGet,
  invHdrsDateRange,
} from '../../redux/invoiceHeadersSlice';
import { invLinesReset, invLinesNo } from '../../redux/invoiceLinesSlice';
import { DateCell, FloatCell } from '../reusable/Table';
import { newView } from '../../redux/viewHistorySlice';




export default memo(function InvoiceHeaders() {
  const getAccessToken = useAccessToken();


  const dispatch = useDispatch();
  const hdrs = useSelector((state) => state.invHdrs);

  const [mode, setMode] = useState('view-data');

  useEffect(() => {
    async function getData() {
      const token = await getAccessToken();
      const params = [
        { key: 'start', value: hdrs?.start },
        { key: 'end', value: hdrs?.end },
      ];
      const res = await Api.GET('/invoices/headers', { accessToken: token, params: params });
      if (res.status === 200) {
				dispatch(invHdrsData(res?.data));
      } else if (res.status === 470) {
        return;
      } else {
				dispatch(invHdrsError(res.status));
			}
    }

    if (hdrs.get) {
      dispatch(invHdrsData(null));
      dispatch(invHdrsGet(false));
      getData();
    }
  }, [dispatch, getAccessToken, hdrs]);


  const setError = useCallback((_error) => {
    dispatch(invHdrsError(_error));
  }, [dispatch]);
  const setGet = useCallback((_status) => {
    dispatch(invHdrsGet(_status));
  }, [dispatch]);
  const setDateRange = useCallback((_range) => {
    dispatch(invHdrsDateRange(_range));
  }, [dispatch]);
  
  const viewLines = useCallback((line) => {
    const invNo = line.No;
    dispatch(invLinesReset());
    dispatch(invLinesNo(invNo));
    dispatch(newView('inv-lines'));
  }, [dispatch]);

  const columns = useMemo(() => [
    {
      Header: 'Invoice No',
      accessor: 'No',
      filter: true,
      filterType: 'search',
      onClick: viewLines,
    },
    {
      Header: 'Type',
      accessor: 'Type',
      filter: true,
      filterType: 'multiple-choice',
    },
    {
      Header: 'Location Code',
      accessor: 'LocationCode',
      filter: true,
      filterType: 'multiple-choice',
    },
    {
      Header: 'Name',
      accessor: 'ShipToName',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Address',
      accessor: 'ShipToAddress',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Date',
      accessor: 'RaisedDate',
      filter: false,
      filterType: 'search',
      Cell: DateCell,
    },
    {
      Header: 'Amount',
      accessor: 'Amount',
      filter: true,
      filterType: 'between',
      Cell: FloatCell,
    },
    {
      Header: 'Amount Inc. VAT',
      accessor: 'AmountIncludingVAT',
      filter: true,
      filterType: 'between',
      Cell: FloatCell,
    },
  ], [viewLines]);


  return (
    <div className="h-full w-full">
      <DateRangeView
        page='inv-hdrs'
        data={hdrs}
        setGet={setGet} setError={setError}
        setDateRange={setDateRange}
        columns={columns}
        mode={mode} setMode={setMode}
        title="Invoices"
        fileName="invoice-headers.csv"
      />
    </div>
  )
});

