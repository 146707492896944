import { memo, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAccessToken } from '../../hooks';
import Api from '../../Api';
import {
  BackButton,
  NewViewButton,
} from '../reusable/Buttons';


import {
  invLinesData,
  invLinesError,
  invLinesGet,
} from '../../redux/invoiceLinesSlice';
import { FloatCell } from '../reusable/Table';
import { SimpleView } from '../reusable/DataViews';

export default memo(function SalesOrderLines() {


  const getAccessToken = useAccessToken();
  const dispatch = useDispatch();

  const lines = useSelector((state) => state.invLines);

  const columns = useMemo(() => [
    {
      Header: 'Description',
      accessor: 'Description',
      filter: true,
      filterType: 'multiple-choice',
    },
    {
      Header: 'Unit of Measure',
      accessor: 'UnitOfMeasure',
      filter: false,
      filterType: 'search',
    },
    {
      Header: 'Quantity',
      accessor: 'Quantity',
      filter: true,
      filterType: 'between',
      Cell: FloatCell,
    },
    {
      Header: 'Unit Price',
      accessor: 'UnitPrice',
      filter: true,
      filterType: 'between',
      Cell: FloatCell,
    },
    {
      Header: 'Amount',
      accessor: 'Amount',
      filter: true,
      filterType: 'between',
      Cell: FloatCell,
      columnTotal: true,
    },
    {
      Header: 'VAT %',
      accessor: 'VAT',
      filter: true,
      filterType: 'between',
      Cell: FloatCell,
    },
    {
      Header: 'Amount Inc. VAT',
      accessor: 'AmountIncludingVAT',
      filter: true,
      filterType: 'between',
      Cell: FloatCell,
      columnTotal: true,
    },
  ], [])

  useEffect(() => {
    async function getData() {
      const token = await getAccessToken();
      const params = [
        { key: 'invoiceNo', value: lines.invoiceNo },
      ];
      const res = await Api.GET('/invoices/lines', { accessToken: token, params: params });
      if (res.status === 200) {
				dispatch(invLinesData(res?.data));
      } else if (res.status === 470) {
        return;
      } else {
				dispatch(invLinesError(res.status));
			}
    }

    if (lines.get) {
			dispatch(invLinesGet(false));
			dispatch(invLinesData(null));
      getData();
    }
  }, [dispatch, getAccessToken, lines]);

  const setError = useCallback((_error) => {
    dispatch(invLinesError(_error));
  }, [dispatch]);

  const setGet = useCallback((_status) => {
    dispatch(invLinesGet(_status));
  }, [dispatch]);

  return (
    <div className="h-full w-full">
      <div className="w-full h-[5%]">
        <div className="w-full flex">
          <BackButton />
          <NewViewButton
            view='inv-pdf'
            text='View PDF'
          />
        </div>
      </div>
      <div className="w-full h-[95%]">
        <SimpleView
          showColumnTotals={true}
          page='inv-lines'
          fileName={`${lines?.invoiceNo}.csv`}
          title={`Invoice Lines - ${lines?.invoiceNo}`}
          columns={columns}
          data={lines}
          setError={setError} setGet={setGet}
        />
      </div>
    </div>
  );
});

