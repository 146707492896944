import { createSlice } from '@reduxjs/toolkit';

export const stockAtDateSlice = createSlice({
  name: 'stockAtDate',
  initialState: {
    data: null,
    date: new Date().toISOString().split('T')[0],
    get: true,
    error: 0,
  },
  reducers: {
    stockAtDateData: (state, action) => {
      state.data = action.payload;
    },
    stockAtDateGet: (state, action) => {
      state.get = action.payload;
    },
    stockAtDateError: (state, action) => {
      state.error = action.payload;
    },
    stockAtDateDate: (state, action) => {
      state.date = action.payload;
    },
    stockAtDateReset: (state) => {
      state.data = null;
      state.get = true;
      state.error = 0;
      state.date = '2024-08-01';
    },
  },
});

export const {
  stockAtDateData,
  stockAtDateGet,
  stockAtDateError,
  stockAtDateDate,
  stockAtDateReset,
} = stockAtDateSlice.actions;

export default stockAtDateSlice.reducer;

