import { configureStore } from '@reduxjs/toolkit'

import sessionReducer from './sessionSlice';
import viewHistoryReducer from './viewHistorySlice';
import overviewReducer from './overviewSlice';
import stockReducer from './stockSlice';
import salesOrdersReducer from './salesOrdersSlice';
import transactionsReducer from './transactionsSlice';
import salesOrderHeadersReducer from './salesOrderHeadersSlice';
import salesOrderLinesReducer from './salesOrderSlice';
import invoiceHeadersReducer from './invoiceHeadersSlice';
import invoiceLinesReducer from './invoiceLinesSlice';
import soRequestReducer from './soRequestSlice';
import stockAtDateReducer from './stockAtDateSlice';

export default configureStore({
  reducer: {
    session: sessionReducer,
    viewHistory: viewHistoryReducer,
    overview: overviewReducer,
    stock: stockReducer,
    stockAtDate: stockAtDateReducer,
    salesOrders: salesOrdersReducer,
    transactions: transactionsReducer,
    soHdrs: salesOrderHeadersReducer,
    soLines: salesOrderLinesReducer,
    invHdrs: invoiceHeadersReducer,
    invLines: invoiceLinesReducer,
    soRequest: soRequestReducer,
  },
});
