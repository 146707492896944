import { memo, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAccessToken } from '../../hooks';
import Api from '../../Api';
import { SimpleView } from '../reusable/DataViews';
import {
  BackButton,
} from '../reusable/Buttons';
import {
  soLinesData,
  soLinesError,
  soLinesGet,
} from '../../redux/salesOrderSlice';

import { FloatCell } from '../reusable/Table';

export default memo(function SalesOrderLines() {

  const getAccessToken = useAccessToken();
  const dispatch = useDispatch();

  const soLines = useSelector((state) => state.soLines);

  const columns = useMemo(() => [
    {
      Header: 'Product Code',
      accessor: 'sku',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Description',
      accessor: 'description',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Vintage',
      accessor: 'vintage',
      filter: true,
      filterType: 'multiple-choice',
    },
    {
      Header: 'Bottle Vol (cl)',
      accessor: 'unitVol',
      filter: true,
      filterType: 'multiple-choice-numeric',
      Cell: FloatCell,
    },
    {
      Header: 'Transaction UoM',
      accessor: 'uom',
      filter: true,
      filterType: 'multiple-choice',
    },
    {
      Header: 'Quantity',
      accessor: 'qty',
      filter: true,
      filterType: 'between',
    },
    {
      Header: '9L Cases',
      accessor: 'qty9L',
      filter: true,
      filterType: 'between',
      Cell: FloatCell,
    },
  ], [])

  useEffect(() => {
    async function getData() {
      const token = await getAccessToken();
      const params = [
        { key: 'orderNo', value: soLines.orderNo },
      ];
      const res = await Api.GET('/sales-orders/lines', { accessToken: token, params: params });
      if (res.status === 200) {
				dispatch(soLinesData(res?.data));
      } else if (res.status === 470) {
        return;
      } else {
				dispatch(soLinesError(res.status));
			}
    }

    if (soLines.get) {
			dispatch(soLinesGet(false));
			dispatch(soLinesData(null));
      getData();
    }
  }, [dispatch, getAccessToken, soLines]);

  const setError = useCallback((_error) => {
    dispatch(soLinesError(_error));
  }, [dispatch]);

  const setGet = useCallback((_status) => {
    dispatch(soLinesGet(_status));
  }, [dispatch]);

  return (
    <div className="h-full w-full">
      <div className="w-full h-[5%]">
        <div className="w-fit">
          <BackButton />
        </div>

      </div>

      <div className="w-full h-[95%]">
        <SimpleView
          page='so-lines'
          fileName={`${soLines.orderNo}.csv`}
          title={`Order Lines - ${soLines.orderNo}`}
          columns={columns}
          data={soLines}
          setError={setError} setGet={setGet}
        />
      </div>
    </div>
  );
});
