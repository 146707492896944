import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';


import { useLogout } from '../hooks';
import menuIcon from '../resources/menu.png';
import userIcon from '../resources/user.png';
import logo from '../resources/white-logo.png';
import Profile from './Profile';
//import LiveStock from './stock/LiveStock';
import StockAtDate from './stock/StockAtDate';
import Movements from './stock/Movements';
import SalesOrderHeaders from './sales-orders/SalesOrderHeaders';
import SalesOrderLines from './sales-orders/SalesOrderLines';
import InvoiceHeaders from './invoices/InvoiceHeaders';
import InvoiceLines from './invoices/InvoiceLines';
//import InvoicePDFs from './documents/PDFList';
import InvoicePDF from './documents/InvoicePDF';
import Contract from './Contract';
import Spacer from './reusable/Spacer';
import { newView } from '../redux/viewHistorySlice';


function NavMenu({ options }) {

  let headers = [];
  let cols = {};
  for (let o = 0; o < options.length; o++) {
    const option = options[o];
    if (!headers.includes(option.category)) {
      headers.push(option.category);
      cols[`${option.category}`] = [];
    }
    cols[`${option.category}`].push(options[o]);
  }


  return (
    <div className="w-full flex justify-end border-b border-[white]">
      {headers.map((header, h) => {return (
        <div key={h} className="w-fit px-[2em] py-[1em]">
          <h2 className="text-txtNavHdr text-2xl">{header}</h2>
          {cols[`${header}`].map((col, c) => {return (
            <div key={c}>
              <button onClick={col.action} className="text-txtNavHdr hover:underline">{col.label}</button>
            </div>
          )})}
        </div>
      )})}
    </div>
  );
}


function LoggedIn() {

  const logout = useLogout();
  const dispatch = useDispatch();

  const {
    user,
  } = useAuth0();

  // navigation / navigation banner state
  const [drop, setDrop] = useState('');
  //const [view, setView] = useState('account-details');
  const view = useSelector((state) => state.viewHistory.history[state.viewHistory.history.length - 1]);

  const accountOptions = useMemo(() => [
    {category: "Account", label: "Profile", action: () => dispatch(newView('account-details'))},
    //{category: "Account", label: "Contract", action: () => dispatch(newView('contract'))},
    {category: "Account", label: "Logout", action: () => logout()},
  ], [dispatch, logout]);


  const dataOptions = useMemo(() => [
    //{category: "Stock", label: "Stock", action: () => dispatch(newView('live-stock'))},
    {category: "Stock", label: "Stock at Date", action: () => dispatch(newView('stock-at-date'))},
    {category: "Stock", label: "Stock Movements", action: () => dispatch(newView('movements'))},

    {category: "Orders", label: "Sales Orders", action: () => dispatch(newView('so-hdrs'))},
    {category: "Invoices", label: "Invoices", action: () => dispatch(newView('inv-hdrs'))},
    //{category: "Invoices", label: "Invoice PDFs", action: () => dispatch(newView('inv-pdfs'))},

  ], [dispatch]);

  return (
    <div className="h-full w-full">

      {/*----------------------- Navigation -----------------------*/}


      <div className={drop === '' ? "h-[3.5em] flex justify-around bg-bgNavHdr1 border-b border-[white]"
      : "h-[3.5em] flex justify-around bg-bgNavHdr1 border-b border-bgNavHdr2"}>
        <div className="h-full w-4/12">
          <div className="pl-[4em] h-full w-fit flex justify-center flex-col">
            <img src={logo} alt="Logo" className="h-[60%] w-auto" />
          </div>
        </div>
        <div className="h-full w-4/12 text-txtNavHdr flex flex-col justify-around">
          <div className="w-full text-2xl text-center">
            {user?.customer_name}
          </div >
        </div >
        <div className="h-full w-4/12 flex justify-end pr-[5em]">
          <div onMouseOver={() => setDrop('data')} className={drop === 'data' ? "h-full w-[5em] bg-bgNavHdr2" : "h-full w-[5em]"}>
            <button className="h-full w-full flex justify-around">
              <img src={menuIcon} alt="Menu" className="h-full w-auto" />
            </button>
          </div>
          <div onMouseOver={() => setDrop('account')} className={drop === 'account' ? "h-full w-[5em] bg-bgNavHdr2" : "h-full w-[5em]"}>
            <button className="h-full w-full flex justify-around">
              <img src={userIcon} alt="Account details" className="h-full w-auto" />
            </button>
          </div>
        </div>
      </div>


      <div className="h-[calc(100%-3.5em)] w-full relative">


        {drop !== '' ? (
          <div className="absolute top-0 left-0 z-50 h-full w-full bg-bgBlur/50 backdrop-blur-sm">
            <div onMouseLeave={() => setDrop('')} className="w-full bg-bgNavHdr2">
              <NavMenu options={drop === 'data' ? dataOptions : accountOptions} />
            </div>
          </div>
        ) : (<></>)}




        {/*----------------------- Views -----------------------*/}

        <div className="h-full w-full bg-bgBlur/20">
          <div className="h-full w-full px-[2em] bg-bgMain">
            {view === 'account-details' ? (
              <div className='w-full h-full'>
                <Profile />
              </div>
            ) : (<></>)}
            {/*{view === 'live-stock' ? (
              <>
                <Spacer />
                <div className='w-full h-[98%]'>
                  <LiveStock />
                </div>
              </>
            ) : (<></>)}*/}
            {view === 'stock-at-date' ? (
              <>
                <Spacer />
                <div className='w-full h-[98%]'>
                  <StockAtDate />
                </div>
              </>
            ) : (<></>)}
            {view === 'movements' ? (
              <>
                <Spacer />
                <div className='w-full h-[98%]'>
                  <Movements />
                </div>
              </>
            ) : (<></>)}
            {view === 'so-hdrs' ? (
              <>
                <Spacer />
                <div className='w-full h-[98%]'>
                  <SalesOrderHeaders />
                </div>
              </>
            ) : (<></>)}
            {view === 'so-lines' ? (
              <>
                <Spacer />
                <div className='w-full h-[98%]'>
                  <SalesOrderLines />
                </div>
              </>
            ) : (<></>)}
            {view === 'inv-hdrs' ? (
              <>
                <Spacer />
                <div className='w-full h-[98%]'>
                  <InvoiceHeaders />
                </div>
              </>
            ) : (<></>)}
            {view === 'inv-lines' ? (
              <>
                <Spacer />
                <div className='w-full h-[98%]'>
                  <InvoiceLines />
                </div>
              </>
            ) : (<></>)}
            {/*
            {view === 'inv-pdfs' ? (
              <>
                <Spacer />
                <div className='w-full h-[98%]'>
                  <InvoicePDFs />
                </div>
              </>
            ) : (<></>)}
            */}
            {view === 'inv-pdf' ? (
              <>
                <Spacer />
                <div className='w-full h-[98%]'>
                  <InvoicePDF />
                </div>
              </>
            ) : (<></>)}
            {view === 'contract' ? (
              <>
                <Spacer />
                <div className='w-full h-[98%]'>
                  <Contract />
                </div>
              </>
            ) : (<></>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoggedIn;
