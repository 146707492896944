import { createSlice } from '@reduxjs/toolkit';

export const viewHistorySlice = createSlice({
  name: 'viewHistory',
  initialState: {
    history: ['account-details'],
  },
  reducers: {
    newView: (state, action) => {
      state.history.push(action.payload);
    },
    back: (state) => {
      state.history.pop();
    },
  },
});

export const {
  newView,
  back,
} = viewHistorySlice.actions;

export default viewHistorySlice.reducer;

