import { useEffect, useState } from "react";
import { useAccessToken } from "../../hooks";
import { useSelector } from "react-redux";
import { BackButton } from "../reusable/Buttons";

const backend = process.env.REACT_APP_API_URL;

function InvoicePDF() {

  const getAccessToken = useAccessToken();

  const [token, setToken] = useState(null);

  useEffect(() => {
    async function getTokenOnLoad() {
      const t = await getAccessToken();
      setToken(t);
    }
    if (!token) {
      getTokenOnLoad();
    }
  }, [getAccessToken, token]);

  const selected = useSelector(state => state.invLines.invoiceNo);
  
  return (
    <div className="h-full w-full">
      <div className="w-full h-[5%]">
        <div className="w-fit">
          <BackButton />
        </div>
      </div>
      {token && selected &&
        <iframe
          title="Invoice PDF"
          className="h-full w-full"
          src={backend + "/invoices/pdf?token=" + token + "&name=" + selected}
        />
      }
    </div>
  );
}

export default InvoicePDF;

