import { useEffect, useState } from 'react';

export function BoolFilter({ column, addFilter, clearFilter, appliedFiltersMap }) {

  return (
    <>
      <th className="text-left pr-[1em]">{column.Header}</th>
      <td>
        <div className="h-full w-full flex justify-between">

          <select
            className='w-full border-2 border-bdrTblRows rounded-md'
            onChange={(e) => {
              if (e.target.value === '') {
                clearFilter(column.accessor);
              } else {
                addFilter(column.accessor, e.target.value, true, false);
              }
            }}
            value={appliedFiltersMap[column.accessor]?.value ?? ''}
          >
            <option value=''>All</option>
            <option value='1'>Yes</option>
            <option value='0'>No</option>
          </select>

          <div className="w-[.25em]"></div>
        </div>
      </td>
    </>
  )
}




export function MultipleChoiceColumnFilter({ column, data, addFilter, clearFilter, appliedFiltersMap, isNumeric = false }) {

  const [choices, setChoices] = useState([]);

  useEffect(() => {
    const _choices = {};
    data.forEach((row) => {
      const value = row[column.accessor];
      if (value) {
        _choices[value] = true;
      }
    });
    setChoices(Object.keys(_choices));
  }, [data, column.accessor]);

  return (
    <>
      <th className="text-left pr-[1em]">{column.Header}</th>
      <td>
        <div className="h-full w-full flex justify-between">

          <select
            className='w-full border-2 border-bdrTblRows rounded-md'
            onChange={(e) => {
              if (e.target.value === '') {
                clearFilter(column.accessor);
              } else {
                addFilter(column.accessor, isNumeric ? Number(e.target.value) : e.target.value, true, false);
              }
            }}
            value={appliedFiltersMap[column.accessor]?.value ?? ''}
          >
            <option value=''>All</option>
            {choices.map((choice) => (
              <option key={choice} value={choice}>{choice}</option>
            ))}
          </select>

          <div className="w-[.25em]"></div>
        </div>
      </td>
    </>
  )
}



export function TextColumnFilter({ column, addFilter }) {

  return (
    <>
      <th className="text-left pr-[1em]">{column.Header}</th>

      <td>
        <div className="h-full w-full flex justify-between">
          <input
            placeholder="Search"
            className="w-full border-2 border-bdrTblRows rounded-md"
            value={column.filterValue || ''}
            onChange={(e) => addFilter(column.accessor, e.target.value, true, true)}
          />
          <div className="w-[.25em]"></div>
        </div>
      </td>

    </>
  );
}




export function NumColumnFilter({ column, addFilter, clearFilter }) {

  // Effect for clearing the filter if the min and max are empty.
  useEffect(() => {
    if (typeof(column?.filterValue) === 'object' // if the column has a filter value
      && ( // if the filter value has empty min and max
        (column?.filterValue?.min || '') === '' && (column?.filterValue?.max || '') === ''
      )
    ) {
      clearFilter(column.accessor);
    }
  }, [column, clearFilter]);

  return (
    <>
      <th className="text-left pr-[1em]">{column.Header}</th>

      <td>
        <div className="h-full w-full flex justify-between">
          <input
            type="number"
            placeholder="Min"
            className="w-[50%] border-2 border-bdrTblRows rounded-md"
            value={column.filterValue?.min || ''}
            onChange={(e) => addFilter(column.accessor, {min: e.target.value, max: column.filterValue?.max})}
          />
          <input
            type="number"
            placeholder="Max"
            className="w-[50%] border-2 border-bdrTblRows rounded-md"
            value={column.filterValue?.max || ''}
            onChange={(e) => addFilter(column.accessor, {min: column.filterValue?.min, max: e.target.value})}
          />
        </div>
      </td>

    </>
  );
}





export function FilterBox({ tableInstance }) {

  const {
    columns,
    data,
    addFilter,
    clearFilter,
    clearAllFilters,
    appliedFilters,
    appliedFiltersMap,
  } = tableInstance;



  return (
    <div className="h-full w-full px-[1em] text-txtTblData">
      <div className="h-[1em]"></div>

      <div className="flex justify-left">

        <table className="h-fit text-sm mr-[2em]"><tbody>
          {columns.map((column, index) => {
            return (
              <tr key={index}>
                {column.filter && column.filterType === 'search' ?
                  <TextColumnFilter column={column} addFilter={addFilter} />
                  : column.filter && column.filterType === 'bool' ?
                  <BoolFilter
                    column={column}
                    addFilter={addFilter}
                    clearFilter={clearFilter}
                    appliedFiltersMap={appliedFiltersMap}
                  />
                  : column.filter && column.filterType === 'multiple-choice' ?
                  <MultipleChoiceColumnFilter
                    column={column}
                    addFilter={addFilter}
                    clearFilter={clearFilter}
                    appliedFiltersMap={appliedFiltersMap}
                    data={data}
                    isNumeric={false}
                  />
                  : column.filter && column.filterType === 'multiple-choice-numeric' ?
                  <MultipleChoiceColumnFilter
                    column={column}
                    addFilter={addFilter}
                    clearFilter={clearFilter}
                    appliedFiltersMap={appliedFiltersMap}
                    data={data}
                    isNumeric={true}
                  />
                  : <></>
                }
              </tr>
            );
          })}
        </tbody></table>

        <table className="h-fit text-sm ml-[2em]"><tbody>
          {columns.map((column, index) => {
            return (
              <tr key={index}>
                {column.filter && column.filterType === 'between' ?
                  <NumColumnFilter column={column} addFilter={addFilter} clearFilter={clearFilter} />
                  : <></>
                }
              </tr>
            );
          })}
        </tbody></table>

        <div className="mx-auto">
          <button
            className={appliedFilters.length === 0 ? "p-[1em] bg-bgRedButton hover:bg-bgRedButtonHov rounded-md text-txtNavHdr" :
              "p-[1em] bg-bgRedButtonHov rounded-md text-txtNavHdr"}
            onClick={clearAllFilters}
          >Reset</button>
        </div>

      </div>

      <div className="h-[1em]"></div>
    </div>
  );
}
