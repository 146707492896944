import { useEffect, useCallback, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAccessToken } from '../../hooks';
import {
	stockAtDateData,
	stockAtDateGet,
	stockAtDateError,
	stockAtDateDate,
} from '../../redux/stockAtDateSlice';
import { FloatCell } from '../reusable/Table';
import { SingleDateView } from '../reusable/DataViews';
import Api from '../../Api';

function StockAtDate() {
  const getAccessToken = useAccessToken();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.stockAtDate);

  // Effect for getting stock data
  useEffect(() => {
    async function getData() {
      const token = await getAccessToken();
      const res = await Api.GET('/stock-at-date', { accessToken: token, params: [{ key: 'date', value: data.date }] });
      if (res.status === 200) {
        dispatch(stockAtDateData(res?.data));
      } else if (res.status === 470) {
        return;
      } else {
        dispatch(stockAtDateError(res.status));
			}
    }

    if (data.get) {
      dispatch(stockAtDateData(null));
      dispatch(stockAtDateGet(false));
      getData();
    }
  }, [getAccessToken, data, dispatch]);

  const columns = useMemo(() => [
    {
      Header: 'Product Code',
      accessor: 'sku',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Description',
      accessor: 'description',
      filter: true,
      filterType: 'search',
    },
    {
      Header: 'Vintage',
      accessor: 'vintage',
      filter: true,
      filterType: 'multiple-choice',
    },
    {
      Header: 'Case UoM',
      accessor: 'caseUOM',
      filter: true,
      filterType: 'multiple-choice',
    },
    {
      Header: 'Bottle Vol (cl)',
      accessor: 'unitVol',
      filter: true,
      filterType: 'multiple-choice-numeric',
      Cell: FloatCell,
    },
    {
      Header: 'Cases',
      accessor: 'remainingCases',
      filter: true,
      filterType: 'between',
    },
    {
      Header: 'Bottles',
      accessor: 'remainingBottles',
      filter: true,
      filterType: 'between',
    },
    {
      Header: 'Total Bottles',
      accessor: 'remainingBase',
      filter: true,
      filterType: 'between',
    },
    {
      Header: 'Total 9L Cases',
      accessor: 'remainingEqCases',
      filter: true,
      filterType: 'between',
      Cell: FloatCell,
    },
    {
      Header: 'Duty Type',
      accessor: 'duty',
      filter: true,
      filterType: 'multiple-choice',
    },
  ], []);

  const setError = useCallback((_error) => {
    dispatch(stockAtDateError(_error));
  }, [dispatch]);

  const setGet = useCallback((_status) => {
    dispatch(stockAtDateGet(_status));
  }, [dispatch]);

  const setDate = useCallback((_date) => {
    dispatch(stockAtDateDate(_date));
  }, [dispatch]);

  return (
    <div className="h-full w-full">
      <SingleDateView
        page="stock-at-date"
        data={data}
        title="Stock at Date"
        columns={columns}
        setError={setError}
        setGet={setGet}
        setDate={setDate}
        fileName="stock-at-date.csv"
      />
    </div>
  );
}

export default memo(StockAtDate);

